<template>
    <div
        class="flex flex-col rounded-lg border-2 border-cyan-600 bg-white shadow-sm overflow-hidden dark:border-gray-800 dark:bg-gray-950"
    >
        <div class="grid items-center justify-center gap-2 pt-6 px-6">
            <h3 class="text-2xl font-bold">
                {{ capitalizedPlanType }}
            </h3>
            <p
                class="text-3xl font-semibold tracking-wide flex gap-2 font-gothic justify-center"
            >
                <span class="text-cyan-600">{{ planPrice }} AED</span>
                <span class="line-through text-bluewood-600">
                    {{ originalPrice }} AED
                </span>
            </p>
            <p class="text-sm text-gray-500">
                Discounted early access price valid for the next 100 customers.
                Hurry up!
            </p>
        </div>
        <div class="p-6 gap-4 flex-1 flex flex-col justify-between">
            <ul class="space-y-4 text-start">
                <li class="flex items-center gap-2">
                    <CheckIcon class="w-5 h-5 shrink-0 text-cyan-600" />
                    <span
                        >Access to exclusive jobs not on LinkedIn or other job
                        boards</span
                    >
                </li>
                <li class="flex items-center gap-2">
                    <CheckIcon class="w-5 h-5 shrink-0 text-cyan-600" />
                    <span>Advanced filters for job search</span>
                </li>
                <li class="flex items-center gap-2">
                    <CheckIcon class="w-5 h-5 shrink-0 text-cyan-600" />
                    <span>Unlimited applications for more interviews</span>
                </li>
                <li class="flex items-center gap-2">
                    <CheckIcon class="w-5 h-5 shrink-0 text-cyan-600" />
                    <span>Priority access to new features</span>
                </li>
                <!-- Coming soon features -->
                <li class="flex items-center gap-2 relative">
                    <UserPlusIcon class="w-5 h-5 shrink-0 text-green-600" />
                    <span
                        class="bg-yellow-400 shrink-0 text-xs font-bold rounded-full px-2 py-0.5"
                    >
                        Coming Soon
                    </span>
                    <span>List your profile on RemoteDXB.com</span>
                </li>
                <li class="flex items-center gap-2 relative">
                    <SparklesIcon class="w-5 h-5 shrink-0 text-purple-600" />
                    <span
                        class="bg-yellow-400 shrink-0 text-xs font-bold rounded-full px-2 py-0.5"
                    >
                        Coming Soon
                    </span>
                    <span>AI-powered CV optimization</span>
                </li>
                <li class="flex items-center gap-2 relative">
                    <SparklesIcon class="w-5 h-5 shrink-0 text-purple-600" />
                    <span
                        class="bg-yellow-400 shrink-0 text-xs font-bold rounded-full px-2 py-0.5"
                    >
                        Coming Soon
                    </span>
                    <span>AI-driven job matching</span>
                </li>
            </ul>
            <p class="text-sm text-gray-500 dark:text-gray-400">
                Billed {{ planType }}, cancel anytime
            </p>
            <Button
                v-if="!page.props.user?.id"
                @click="() => handleSubscription(planType, planPrice)"
                class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 px-4 py-2 h-10 w-full"
            >
                Subscribe
            </Button>
            <Anchor
                class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 px-4 py-2 h-10 w-full"
                :href="
                    route('cashier.checkout', {
                        plan: 'monthly',
                    })
                "
                v-else
            >
                Subscribe
            </Anchor>
        </div>
    </div>
</template>
<script setup>
import Anchor from "@/Components/Guest/Anchor.vue";
import Button from "@/Components/Guest/Button.vue";
import { usePage } from "@inertiajs/vue3";
import { computed } from "vue";
import { CheckIcon } from "@heroicons/vue/20/solid";
import { SparklesIcon } from "@heroicons/vue/20/solid";
import { UserPlusIcon } from "@heroicons/vue/20/solid";

const capitalizedPlanType = computed(() => {
    return props.planType.charAt(0).toUpperCase() + props.planType.slice(1);
});

const props = defineProps({
    planType: String,
    planPrice: Number,
    originalPrice: Number,
    isSignUpModalOpen: Boolean,
    handleSubscription: Function,
});

const page = usePage();
</script>
