<template>
    <Combobox as="div" multiple>
        <ComboboxLabel
            class="block text-md font-medium leading-6 text-gray-900 pl-3 py-2 rounded-t-md bg-gray-100 shadow-sm ring-1 ring-inset ring-gray-300"
            >{{ label }}</ComboboxLabel
        >
        <div class="relative">
            <ComboboxButton class="w-full bg-white">
                <ComboboxInput
                    placeholder="Select an option"
                    class="w-full rounded-b-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-md sm:leading-6"
                    @change="query = $event.target.value"
                    :display-value="
                        (options) => options.map(({ name }) => name).join(', ')
                    "
                />
                <div
                    class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                >
                    <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </div>
            </ComboboxButton>

            <ComboboxOptions
                v-if="filteredOptions.length > 0"
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-md"
            >
                <ComboboxOption
                    v-for="option in filteredOptions"
                    :key="option.id"
                    :value="option"
                    as="template"
                    v-slot="{ active, selected }"
                >
                    <li
                        :class="[
                            'relative cursor-default select-none py-2 pl-8 pr-4',
                            active ? 'bg-cyan-600 text-white' : 'text-gray-900',
                        ]"
                    >
                        <span
                            :class="[
                                'block truncate',
                                selected && 'font-semibold',
                            ]"
                        >
                            {{ option.name }}
                        </span>

                        <span
                            v-if="selected"
                            :class="[
                                'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                active ? 'text-white' : 'text-cyan-600',
                            ]"
                        >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                </ComboboxOption>
            </ComboboxOptions>
        </div>
    </Combobox>
</template>

<script setup>
import { computed, ref } from "vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from "@headlessui/vue";

const props = defineProps({
    options: {
        type: Object,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
});

const options = ref(props.options);

const query = ref("");

const filteredOptions = computed(() =>
    query.value === ""
        ? options.value
        : options.value.filter((option) => {
              return option.name
                  .toLowerCase()
                  .includes(query.value.toLowerCase());
          }),
);
</script>
